<template lang="html">
    <div>
        <Navbar></Navbar>
    </div>
</template>
<script>
    import Navbar from "./components/Navbar.vue";
    import ContactFooter from  "./components/ContactFooter.vue" 
export default {
    components:{
        Navbar
    }
}
</script>
<style lang="">

</style>
